
    const config = {
  "DB_HOST": "192.168.20.13",
  "DB_PORT": "5432",
  "DB_USER": "postgres",
  "DB_PASSWORD": "root",
  "DB_NAME": "equity_graph",
  "DB_CONNECT": "http://192.168.20.17:3003/",
  "CORS_URL": "http://192.168.20.17:9000/",
  "REACT_APP_DEV_EXTERNAL_URL": "https://thor.intraday.dev.phoenix.zentropylabs.com/",
  "REACT_APP_DEV_INTERNAL_URL": "http://192.168.20.17:9190/",
  "GRAPH_NAME": "'equity_graph'",
  "CLIENT_ID": "cDDWRivGOxF9hrhCJvXoBjHZJDrHOiZT",
  "UI_URL": "https://dev.phoenix.zentropylabs.com/"
};
    export default config;
    