/*eslint-disable*/
import { useState, useEffect } from "react";
import OptionChain from "./OptionChain";
import { useRef } from "react";
import "./OptionChainTemplate.css";
import OrderForm from "components/OrderForm/Field/OrderForm";
import { useDispatch, useSelector } from "react-redux";
import { setDateList, setMonth, setMonthList, setShowOrderForm, setDate,setStrike, setSymbol,setSymbolName,setIsMarketOpen, setBankNiftyDateOptions, setNiftyDateOptions, setFilterModel, setIsFetchingFirst, setIsFetching, setSortOptions, setStrikeList, setNiftyStrikeOptions, setBankNiftyStrikeOptions, setExpiryList } from "./OptionChainSlice";
import { Autocomplete } from "@mui/material";
import TextField from '@mui/material/TextField';
import { getBankNiftyEodPrice, getNiftyEodPrice, getValidBankNiftyZenSecurities, getValidNiftyZenSecurities } from "./fetchData";
import { useRealtimeZenSecurities2 } from "components/getAllSourcesSecurities";
import config from "config";
const OptionChainTemplate = () => 
{
    const [rowData, setRowData] = useState([]);
    const showForm = useSelector(state => state.optionChainTemplate.showOrderForm);
    const tradingSymbol = useSelector(state => state.optionChainTemplate.tradingSymbol);
    const callType = useSelector(state => state.optionChainTemplate.callType);
    const gridApiRef = useRef();
    const date = useSelector(state => state.optionChainTemplate.date);
    const month = useSelector(state => state.optionChainTemplate.month);
    const dateList = useSelector(state => state.optionChainTemplate.dateList);
    const monthList = useSelector(state => state.optionChainTemplate.monthList);
    const dateOptions = useSelector(state => state.optionChainTemplate.dateOptions);
    const symbol = useSelector(state => state.optionChainTemplate.symbol);
    const dispatch = useDispatch();
    const niftyDateOptions = useSelector(state => state.optionChainTemplate.niftyDateOptions);
    const bankNiftyDateOptions = useSelector(state => state.optionChainTemplate.bankNiftyDateOptions);
    const symbolName = useSelector(state => state.optionChainTemplate.symbolName);
    const filterModel = useSelector(state => state.optionChainTemplate.filterModel);
    const isFetchingFirst = useSelector(state => state.optionChainTemplate.isFetchingFirst);
    const isFetching = useSelector(state => state.optionChainTemplate.isFetching);
    const sortOptions = useSelector(state => state.optionChainTemplate.sortOptions);
    const strike = useSelector(state => state.optionChainTemplate.strike);
    const niftyStrikeOptions = useSelector(state => state.optionChainTemplate.niftyStrikeOptions);
    const bankNiftyStrikeOptions = useSelector(state => state.optionChainTemplate.bankNiftyStrikeOptions);
    const strikeList = useSelector(state => state.optionChainTemplate.strikeList);
    const niftyEodPrice = useSelector(state => state.optionChainTemplate.niftyEodPrice);
    const bankNiftyEodPrice = useSelector(state => state.optionChainTemplate.bankNiftyEodPrice);
    // const niftySecurities = useValidNiftySecurities();
    // const bankNiftySecurities = useValidBankNiftySecurities();
    const optionDetails = useRealtimeZenSecurities2();
    const expiryList = useSelector(state => state.optionChainTemplate.expiryList);
    const isMarketOpen = useSelector(state => state.optionChainTemplate.isMarketOpen);
    const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
    
    // console.log("Nifty Securities :", niftySecurities);
    // console.log("Bnak nifty securities : ", bankNiftySecurities);
    const hideForm = () => 
    {
        dispatch(setShowOrderForm(false))
    }

    const clearFilterForColumn = (colId, gridApi) => 
    {
        const filterModel = gridApi?.getFilterModel();
        delete filterModel[colId];
        gridApi?.setFilterModel(filterModel);
    };
    

    const onSelectMonth = (month, gridApi) => {
        const todayDate = new Date()
        let startDate, endDate
        const existingFilterModel = gridApi?.getFilterModel()
        const year = todayDate?.getMonth() === 11 && month === 0 ? todayDate?.getFullYear() +1 : todayDate?.getFullYear();
        if(todayDate?.getMonth() === month)
        {
            startDate = new Date(todayDate)?.toISOString()?.split('T')[0]
            endDate = new Date(year,todayDate?.getMonth() +1 , 1)?.toISOString()?.split('T')[0]
        }
        else
        {
            startDate = new Date(year,month , 2)?.toISOString()?.split('T')[0]
            endDate = new Date(year,month+1 , 1)?.toISOString()?.split('T')[0]
        }
        
            gridApi?.setFilterModel?. 
            (
                {
                    ...gridApi?.getFilterModel?.(),
                    expiry_date : 
                    {
                        filterType : 'date',
                        type : 'inRange',
                        dateFrom : startDate, 
                        dateTo : endDate,  
                        filterParams : 
                        {
                            inRangeInclusive: true,
                        }  
                        
            //             filterType: 'custom',
            // filter: (cellValue) => {
            //     if (!cellValue) return false; // Handle cases where expiry_date might be null or undefined
            //     const cellDate = new Date(cellValue);
            //     return cellDate.getMonth() === month; // Match the selected month
            // },
                    },
                }
            );
//             const monthFilterInstance = gridApi.getFilterInstance('expiry_date');
//             console.log("Month filter instance : ", monthFilterInstance);
//             monthFilterInstance.setFilterMonth(month);
            gridApi?.onFilterChanged?.();
        
        
    }

    const onSelectDate = (date, gridApi) => {
        if (gridApi && date) {
                gridApi?.setFilterModel?.({
                    ...gridApi?.getFilterModel?.(),
                    expiry_date: {
                        filterType: 'date',
                        type: 'equals',
                        dateFrom: date,   
                        filterParams: {
                            inRangeInclusive: true,
                        }                 
                        
                    },
                });
                gridApi?.onFilterChanged?.();
            
           
    }}
    const onSelectSymbol = (symbol, gridApi) => {
        if (gridApi) {
                gridApi?.setFilterModel?.({
                    ...gridApi?.getFilterModel?.(),
                    underlying_zen_id: {
                        filterType: 'number',
                        type: 'equals',
                        filter: symbol,   
                        
                    },
                });
                gridApi?.onFilterChanged?.();                      
        }
    }
    const onSelectStrike = (strike, gridApi) => {
        if (gridApi) {
            
            gridApi?.setFilterModel({
                ...gridApi?.getFilterModel?.(),
                strike: 
                {
                    filterType: 'number',
                    type: 'equals',
                    filter: Number(strike),   
                    
                }
            });
            gridApi?.onFilterChanged?.();       
        }
    }

    
    useEffect(() => {
        const checkMarketStatus = () => {
        const now = new Date();
        const openTime = new Date();
        openTime.setHours(9, 15, 0);

        const closeTime = new Date();
        closeTime.setHours(15, 30, 0);
        if(process.env.REACT_ENV_DEV === 'production')
          dispatch(setIsMarketOpen(now >= openTime && now <= closeTime));
        else
            dispatch(setIsMarketOpen(true));
        };
    
        checkMarketStatus();
      }, []);

   
    
    useEffect(() => {
            const eventSource = new EventSource(`${config.DB_CONNECT}getZenOptionGreeks`);
            eventSource.onopen = () => {
                console.log("Connection to server opened.");
            };
            
            eventSource.onstatus = (event) => {
                console.log(event);
            };
            
            eventSource.onmessage = (event) => {
                const data = JSON.parse(event.data);
                const tradingSymbol = data?.key;
                const api = gridApiRef?.current;
                const rowNode = api?.getRowNode(tradingSymbol);
                if(rowNode) 
                {       
                    api?.applyTransaction({update: [data]})
                } 
                else 
                {
                    api?.applyTransaction({add: [data]})
                }
            };
            
            eventSource.onerror = (err) => {
                console.error('EventSource failed:', err);
                eventSource.close();
            };
            
            optionDetails.then((data)=>{
                let niftyData, bankNiftyData
              niftyData = [...data['CE']?.filter(item => item.trading_symbol?.startsWith("NIFTY")), ...data['PE']?.filter(item => item.trading_symbol?.startsWith("NIFTY"))]
              bankNiftyData = [...data['CE']?.filter(item => item.trading_symbol?.startsWith("BANKNIFTY")), ...data['PE']?.filter(item => item.trading_symbol?.startsWith("BANKNIFTY"))]
              const niftyDate = Array.from(new Set(niftyData?.map(item => item?.expiry))).sort((a,b) => new Date(a) - new Date(b));
              const bankNiftyDate = Array.from(new Set(bankNiftyData?.map(item => item?.expiry))).sort((a,b) => new Date(a) - new Date(b));
              dispatch(setExpiryList({37761 : ["ALL",...niftyDate], 37766: ["ALL", ...bankNiftyDate]}));
              dispatch(setDateList( ["ALL",...niftyDate]));
              dispatch(setStrikeList({37761 : ["ALL",...Array.from(new Set(niftyData?.map(item => item?.strike))).sort()], 37766 : ["ALL",...Array.from(new Set(bankNiftyData?.map(item => item?.strike))).sort()]}));
              dispatch(setMonthList(
                {37761: ["ALL", ...Array.from(new Set(niftyDate?.map(data => monthNames[new Date(data)?.getMonth()])))],
                37766 : ["ALL", ...Array.from(new Set(bankNiftyDate?.map(data => monthNames[new Date(data)?.getMonth()])))]
                }
            ))
             })

             return () => {
               eventSource.close();
             };

            
        
        
      },[isMarketOpen])
    return(

      <div>
        {
          isMarketOpen ?(
            <div style={{width: '100%', paddingLeft:'15px'}}>
          <form style={{zIndex: 1}}>
              <div className = 'form-row' style={{zIndex: 1}}>
              <div className="col-md-3 form-group" >
                  <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      defaultValue={symbol}
                      options={[,
                        { label: "Nifty", value: 37761 },
                        { label: "Bank Nifty", value: 37766 }
                      ]}
                      getOptionLabel={(option) => option.label}
                      disableClearable={true}
                      onChange={(event, value) => 
                      {
                          if(strike !== "ALL")
                          {
                            dispatch(setStrike("ALL"));
                            clearFilterForColumn('strike', gridApiRef.current);
                          }
                          if(date !== "ALL")
                            dispatch(setDate("ALL"));                          
                          if(month !== "ALL")
                          {
                            dispatch(setMonth("ALL"));
                            clearFilterForColumn('expiry_date', gridApiRef.current);
                          }
                          dispatch(setDateList(expiryList[value?.value]));
                          dispatch(setSymbol(value));
                          onSelectSymbol(value.value, gridApiRef.current);
                      }}
                      renderInput={(params) => <TextField {...params} label="Select the symbol"/>}
                  />
                  </div>  
                   <div className = 'col-md-3 form-group'>
                      <Autocomplete
                      disablePortal
                      id="combo-box-month"
                      options={monthList[symbol?.value] ? monthList[symbol?.value] : []}
                      value={month}
                      onChange=
                      {
                          (event, value) => 
                          {
                              let date;
                            //   dispatch(setMonth(value))
                              if(strike!=="ALL") {
                                dispatch(setStrike("ALL"));
                                clearFilterForColumn('strike', gridApiRef.current);
                              };
                              if(date!=="ALL")dispatch(setDate("ALL"));
                             if(value !== "ALL" && value !== null){
                                dispatch(setMonth(value));
                                dispatch(setDateList(expiryList[symbol?.value]?.filter(date => new Date(date)?.getMonth() === monthNames?.indexOf(value) )));
                                onSelectMonth(monthNames?.indexOf(value), gridApiRef.current); 
                              }
                              else{
                                  dispatch(setMonth("ALL"));
                                  dispatch(setDateList(expiryList[symbol?.value]));
                                  clearFilterForColumn('expiry_date', gridApiRef?.current);

                              }
                          }
                      }
                      renderInput={(params) => <TextField {...params} label="Select an expiry month" />}
                      />
                  </div>
                  <div className="col-md-3 form-group" >
                      <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={dateList}
                          value={date}
                          onChange={(event, value) => {
                            if(value !== "ALL" && value !== null){
                              dispatch(setDate(value));
                              onSelectDate(value, gridApiRef.current); 
                            }
                            else{
                                dispatch(setDate("ALL"));
                                if(month)onSelectMonth(monthNames?.indexOf(month),gridApiRef.current)
                                else clearFilterForColumn('expiry_date', gridApiRef?.current);
                            }


                          }}
                          renderInput={(params) => <TextField {...params} label="Select the expiry date"/>}                                       
                      />
                  </div>  

                  <div className="col-md-3 form-group" >
                      <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={strikeList[symbol?.value] ? strikeList[symbol?.value] : []}
                          getOptionLabel={(option) => option.toString()}
                          value={strike}
                          onChange={(event, value) => {
                            if((date !== "ALL" && date !== null) || (month !== "ALL" && month !== null)) 
                                clearFilterForColumn('expiry_date', gridApiRef.current);
                            dispatch(setMonth("ALL"));
                            dispatch(setDate("ALL"));
                            dispatch(setDateList(expiryList[symbol.value]));
                            if(value !== "ALL" && value !== null){                     
                              dispatch(setStrike(value)); 
                              onSelectStrike(value, gridApiRef.current);
                            }
                            else{
                                dispatch(setStrike("ALL"))
                                clearFilterForColumn('strike', gridApiRef.current);
                            }
                          }}
                          renderInput={(params) => <TextField {...params} label="Select the strike"/>}                                       
                      />
                  </div>                              
              </div>
          </form> 
          <div className="option-container">
              <div className="option-table"> 
                  <OptionChain 
                      date={date}
                      month={month}
                      symbol={symbol}
                      onSelectDate = {onSelectDate}
                      onSelectMonth = {onSelectMonth}
                      monthNames = {monthNames}
                      filterModel = {filterModel}
                      setFilterModel = {setFilterModel}
                      sortOptions = {sortOptions}
                      setSortOptions = {setSortOptions}
                      dispatch = {dispatch}
                      gridApiRef={gridApiRef}
                      setGridApi={(api) => {
                          gridApiRef.current = api;
                      }}
                  />
              </div>
              <div className="order-form">
                  {showForm && <OrderForm
                      targetNode = {tradingSymbol}
                      trade= {callType}
                      setFormOpen={setShowOrderForm}
                      hideForm={hideForm}
                  />}
              </div>
          </div>
      </div>
          ) :(
            <div style={{ textAlign: 'center', marginTop: '20%' }}>
              <h1>Market is closed now</h1>
              <p>Market hours are from 9:15 AM to 3:30 PM</p>
            </div>
          )

        }
      </div>
              
       
      )

        
}

export default OptionChainTemplate;