/* eslint-disable */
import React from "react";
import ReactDOM from 'react-dom';
import { BrowserRouter, useNavigate} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/phoenix-dashboard.scss?v=1.3.0";
import "./assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { Provider, useSelector } from 'react-redux';
import store from './app/store';
import styles from "./index.css";
import App from "App";
import 'qtip2/dist/jquery.qtip.min.css';
import { Auth0Provider } from '@auth0/auth0-react';
import config from '../src/config';

const isInternalIP = /^http:\/\/\d{3}\.\d{3}\.\d{2}\.\d{1,2}(:\d+)?$/.test(origin);

const onRedirectCallback = (appState) => {
  // Clean up the URL after redirect
  window.history.replaceState(
    {}, 
    document.title, 
    window.location.pathname
  );

  // You can also perform additional logic here, like redirecting the user to a specific page
  // For example, redirect to the page that the user was trying to access before login
  if (appState && appState.returnTo) {
    window.location.pathname = appState.returnTo;
  }
};

ReactDOM.render(
  isInternalIP ? (
      <Provider store={store}>
        <BrowserRouter>
          <App/>
        </BrowserRouter>
    </Provider>
    ) : (
      <Auth0Provider
          domain={"phoenix-lab.us.auth0.com"}
          clientId={config.CLIENT_ID}
          authorizationParams={{
            redirect_uri: config.UI_URL+'default/dashboard',
            scope: "openid profile email offline_access"
          }}
          cacheLocation="localstorage"
          debug={true}
          onRedirectCallback={onRedirectCallback}
          useRefreshTokens={true} // Ensures session persistence
        >
        <Provider store={store}>
              <BrowserRouter>
                <App/>
              </BrowserRouter>
          </Provider>
        </Auth0Provider>),
  document.getElementById('root')
);