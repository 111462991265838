/*eslint-disable*/
import { useDispatch, useSelector } from "react-redux";
import { setOpen, setPendingData, setOrderType, setOrderValue, setType, setUpdateData, setIsUpdateForm, setIsDeleteBtn, setLots, setLotSize, setRefreshTriggerCount, setShowOrderResponse, setOrderStatus, setOrderMessage, setEnableTriggerPoints } from "./orderFormSlice";
import { setShowOrderForm } from "components/OptionChain/OptionChainSlice";
import { saveFormOpen, saveTradingSymbol } from "components/PositionsGrid/container/PositionActionSlice";
import { setTradingSymbol } from "components/OptionChain/OptionChainSlice";
import getAvailableMargin from "components/Margin/container/cypherResult";
import fetchBetaValues from "components/Beta/getPortfoliobeta";
import { setAvailableMargin } from "components/Margin/container/MarginTemplateSlice";
import { useGlobalState } from "components/globalVariable";
import { selectBroker, selectAccount, selectStrategy } from 'reducers/BrokerSlice.js';
import { setShowForm } from "components/GraphTemplate/container/GraphTemplateSlice";
import { setGraphTradingSymbol } from "components/CytoscapeGraph/container/MainTemplateSlice";
import { apiConfig } from "apiConfig";

const useFormAction = (setError, setFormOpen, setZen, reset, setValue) => {
    const dispatch = useDispatch();
    const orderValue = useSelector(state => state.orderForm.orderValue);
    const updateData = useSelector(state => state.orderForm.updateData);
    const refreshTriggerCount = useSelector(state => state.orderForm.refreshTriggerCount);
    const lotSize = useSelector(state => state.orderForm.lotSize);
    const [clientId]                  = useGlobalState("clientId");
    const [accountIds]                = useGlobalState("accountIds");
    const isUpdateForm = useSelector(state => state.orderForm.isUpdateForm);
    const isDeleteBtn = useSelector(state => state.orderForm.isDeleteBtn);
    const strategy = useSelector(state => state.filter.strategy);
    const account = useSelector(state => state.filter.account);
    const broker = useSelector(state => state.filter.broker);
    const {selectedAccount} = useSelector(state => state.clientSelection);
    const handleDialogClose = (confirmed, pendingData, dispatch) => {
        dispatch(setOpen(false));
        if (confirmed && pendingData) {
          onSubmit(pendingData);
        }else{
          setError("quantity", { type: "manual", message:"Enter value less than 1000" }, { shouldFocus: true })
        }
      };
    
    const handleFormSubmit = (data) => {
        if(data.quantity%lotSize!==0){
        setError("quantity", { type: "manual", message:"Invalid Quantity!" }, { shouldFocus: true })
        return;
        }
        if (data.quantity > 1000) {
        dispatch(setPendingData(data));
        dispatch(setOpen(true));
        } else {
        onSubmit(data);
        }
    };
    
    const handleChange = (selectedOption) => {
        if(updateData !== undefined)
        {
          dispatch(setUpdateData({...updateData, orderType : selectedOption}));
        }
        else
        {
          dispatch(setOrderType(selectedOption));
        }
        switch (selectedOption) {
          case "MARKET":
            dispatch(setOrderValue(["triggerPrice", "limitPrice", "stopLossLimitPrice"]));
            break;
          case "LIMIT":
            dispatch(setOrderValue(["triggerPrice", "stopLossLimitPrice"]));
            break;
          case "STANDALONE_SL_LIMIT":
            dispatch(setOrderValue(["limitPrice"]));
            break;
          case "STANDALONE_SL_MARKET":
            dispatch(setOrderValue(["limitPrice","stopLossLimitPrice"]));
            break;
          case "LIMIT_ORDER_WITH_SL_LIMIT":
            dispatch(setOrderValue([]));
            break;
          case "MARKET_ORDER_WITH_SL_LIMIT":
            dispatch(setOrderValue(["limitPrice"]));
            break;
          case "LIMIT_ORDER_WITH_SL_MARKET":
            dispatch(setOrderValue(["stopLossLimitPrice"]));
            break;
          case "MARKET_ORDER_WITH_SL_MARKET":
            dispatch(setOrderValue(["limitPrice", "stopLossLimitPrice"]));
            break;
          case "TRAILING_STOP_LOSS_MARKET_ORDER":
              dispatch(setOrderValue(["trailingLimitPoints"]));
              break;
          default:
            dispatch(setOrderValue([]));
            break;
        }
        if(selectedOption === "TRAILING_STOP_LOSS_MARKET_ORDER" || selectedOption === "TRAILING_STOP_LOSS_LIMIT_ORDER")
          dispatch(setEnableTriggerPoints(true));
        else
          dispatch(setEnableTriggerPoints(false));
      };
    
    const handleTypeChange = (event, value) => {
        dispatch(setType(value));
        setValue("tradingSymbol",null);
        setValue("quantity","1")
    };

    
    function onSubmit(data) {
      
      data.broker =  typeof data.broker !== "string"? data.broker.broker_name : data.broker
        if (data.validity !== "Minute") {
          data.minutes = null;
        }
        
        if(data.minutes!==null){
          data.minutes=Number(data.minutes)
        }
        for (const i of orderValue) {
          data[i]=null;
        }
        data.strategyId         = parseInt(data.strategyId)
        data.quantity           = parseInt(data.quantity);
        data.limitPrice         = parseFloat(data.limitPrice);
        data.triggerPrice       = parseFloat(data.triggerPrice);
        data.stopLossLimitPrice = parseFloat(data.stopLossLimitPrice);
        data.trailingTriggerPoints = parseFloat(data.trailingTriggerPoints);
        data.trailingLimitPoints = parseFloat(data.trailingLimitPoints);
        data.activationPoints = parseFloat(data.activationPoints);
        data.methodType  = isDeleteBtn ? 'DELETE': isUpdateForm ? 'PUT' :  'POST';
        if(isUpdateForm)
          {
            data.zenOrderId = updateData?.zenOrderId
            //setValue("zenId",updateData?.zenId)
            data.zenId = updateData?.zenId
          }
          

        delete data.tradingSymbol;
        // delete data.exchange;
        // delete data.activationPoints;

        fetch(apiConfig.THOR_URL+'order/placeOrder',
            {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                body:JSON.stringify(data),
            })
            .then(response=>{
                if (!response.ok) {
                  throw new Error('Not able to place the order');
                }
                return response.json();
              }
            )
            .then(json=>{
              dispatch(setShowOrderResponse(true));
              dispatch(setOrderStatus(json.status));
              dispatch(setOrderMessage(json.message));
              setTimeout(()=>dispatch(setRefreshTriggerCount(refreshTriggerCount+1)),1000);
            })
            .catch((err) => {
              dispatch(setShowOrderResponse(true));
              dispatch(setOrderStatus("Error"));
              dispatch(setOrderMessage("Not able to place the order"));
          });
        getAvailableMargin(clientId,selectedAccount, dispatch, setAvailableMargin);
        fetchBetaValues(clientId,accountIds,dispatch);
        dispatch(setShowOrderForm(false));
        dispatch(saveFormOpen(false))
        dispatch(saveTradingSymbol(""));
        dispatch(setTradingSymbol(""));
        dispatch(setOrderType(null));
        dispatch(setOrderValue([]));
        dispatch(setIsUpdateForm(false));
        dispatch(setIsDeleteBtn(false))
        dispatch(setUpdateData(undefined));
        setFormOpen(true);
        dispatch(setShowForm(false)); //for closing order form in zen security analyser
        reset();
        setZen("");
        dispatch(selectBroker(broker))
        dispatch(selectAccount(account))
        dispatch(selectStrategy(strategy))
        // setValue("broker",broker)
        // setValue("accountId",account)
        // setValue("strategyId",strategy)
        setValue("zenId","")
        setValue("quantity",1)
        dispatch(setType("EQ"));
        dispatch(setLots(1));
        dispatch(setEnableTriggerPoints(false));

      }

    function handleClose(hideForm){
      hideForm(updateData, setUpdateData)
      // if (updateData !== null && updateData !== undefined) {
      //   setUpdateData(null);
      // } else {
      //   setFormOpen((formOpen) => !formOpen);
      // }
      dispatch(setTradingSymbol(""));
      dispatch(setOrderType(null));
      dispatch(setOrderValue([]));
      dispatch(setUpdateData(undefined));
      dispatch(setIsUpdateForm(false));
      dispatch(setIsDeleteBtn(false));
      dispatch(setLotSize(1));
      dispatch(setLots(1));
      dispatch(setGraphTradingSymbol(null));
    }
    return {
        handleDialogClose,
        handleFormSubmit,
        handleChange,
        handleTypeChange,
        onSubmit,
        handleClose
    }
}

export default useFormAction;

